import { ReactElement } from 'react';
import { MembershipPlanStatusEnum } from 'state/membership/membership.helpers';
import { MembershipPlanStatusType } from 'types/membership';
import UIStatuses from 'types/ui-statuses';
import BadgeStatus from 'ui-kit/bagde/badge-status';

interface MembershipLabel {
    planStatus: MembershipPlanStatusType;
    formattedPlanStatus: string;
}

const MembershipManagementBadgeStatus = ({ planStatus, formattedPlanStatus }: MembershipLabel): ReactElement => {
    let badgeStatusVariant: UIStatuses = 'primary';

    switch (planStatus) {
        case MembershipPlanStatusEnum.PaymentDue:
            badgeStatusVariant = 'danger';
            break;
        case MembershipPlanStatusEnum.Active:
            badgeStatusVariant = 'success';
            break;
        case MembershipPlanStatusEnum.OnHold:
            badgeStatusVariant = 'warning-darker';
            break;
        case MembershipPlanStatusEnum.Canceled:
            badgeStatusVariant = 'smoke';
            break;
        default:
            badgeStatusVariant = 'primary';
            break;
    }

    return <BadgeStatus label={formattedPlanStatus} variant={badgeStatusVariant} />;
};

export default MembershipManagementBadgeStatus;
