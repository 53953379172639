import classNames from 'classnames';

import './user-circle-icon.style.scss';

interface UserCircleIconProps {
    className?: string;
}

const UserCircleIcon = ({ className }: UserCircleIconProps) => {
    const containerClasses = classNames('user-circle-icon', { [`${className}`]: className });

    return (
        <div className={containerClasses}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path
                        d="M5.27381 20.7923C6.02899 19.5527 7.09035 18.5282 8.35586 17.8174C9.62137 17.1064 11.0485 16.733 12.5 16.733C13.9515 16.733 15.3786 17.1064 16.6441 17.8174C17.9096 18.5282 18.9709 19.5527 19.7262 20.7923M12.5 14.1923C14.8366 14.1923 16.7308 12.2981 16.7308 9.96154C16.7308 7.62495 14.8366 5.73077 12.5 5.73077C10.1634 5.73077 8.26923 7.62495 8.26923 9.96154C8.26923 12.2981 10.1634 14.1923 12.5 14.1923ZM12.5 23.5C18.5752 23.5 23.5 18.5752 23.5 12.5C23.5 6.42487 18.5752 1.5 12.5 1.5C6.42487 1.5 1.5 6.42487 1.5 12.5C1.5 18.5752 6.42487 23.5 12.5 23.5Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </div>
    );
};

export default UserCircleIcon;
