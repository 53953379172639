import { ReactElement } from 'react';

import './badge-membership.style.scss';

export interface BadgeMembershipProps {
    variant: string;
}

const BadgeMembership = ({ variant }: BadgeMembershipProps): ReactElement => {
    const getVariantPath = (variant: string | undefined) => {
        switch (variant) {
            case 'care': {
                return (
                    <svg width="80" height="24" viewBox="0 0 80 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="80" height="24" rx="12" fill="#222A3F" />
                        <path
                            d="M52.2594 17.2114L54.0789 6.78857C54.1809 6.15429 54.385 6 55.0142 6H61.323C61.9352 6 62.0713 6.15429 61.9692 6.78857L61.9012 7.2C61.7822 7.81714 61.5781 7.98857 60.9659 7.98857H56.0515L55.5413 10.92H59.4185C60.0477 10.92 60.1837 11.0743 60.0647 11.7086L59.9967 12.1029C59.8946 12.7371 59.7076 12.8914 59.0784 12.8914H55.2012L54.6571 16.0114H59.7586C60.3708 16.0114 60.5068 16.1829 60.4048 16.8L60.3368 17.2114C60.2177 17.8457 60.0307 18 59.4185 18H52.9226C52.2934 18 52.1403 17.8457 52.2594 17.2114Z"
                            fill="white"
                        />
                        <path
                            d="M41.5016 17.2114L43.2137 6.78857C43.3097 6.15429 43.5017 6 44.0938 6H47.0539C50.1261 6 51.6142 7.14857 51.1661 9.73714C50.8621 11.5029 49.8221 12.5486 48.126 12.9771L50.2381 17.3314C50.4621 17.8457 50.3661 18 49.8061 18H49.118C48.526 18 48.334 17.8629 48.126 17.3143L46.1419 13.2H44.2378L43.5817 17.2114C43.4697 17.8457 43.3097 18 42.7017 18H42.1257C41.5336 18 41.3896 17.8457 41.5016 17.2114ZM46.7819 7.98857H45.0858L44.5578 11.2286H46.2539C47.966 11.2286 48.814 10.7829 49.006 9.61714C49.198 8.46857 48.51 7.98857 46.7819 7.98857Z"
                            fill="white"
                        />
                        <path
                            d="M37.388 17.28L36.989 15.4114H32.1059L31.1325 17.28C30.8772 17.8629 30.6538 18 30.0952 18H29.2335C28.7867 18 28.6271 17.7257 28.8665 17.28L34.4996 6.72C34.8028 6.13714 35.0103 6 35.5847 6H36.3507C36.9252 6 37.1007 6.13714 37.1965 6.72L39.4944 17.28C39.5742 17.7257 39.3667 18 38.904 18H38.2337C37.6593 18 37.4837 17.8629 37.388 17.28ZM35.6167 8.72571L33.1591 13.4229H36.5901L35.6167 8.72571Z"
                            fill="white"
                        />
                        <path
                            d="M23.5643 18C19.3803 18 17.4323 15.6467 18.1438 11.758C18.8044 8.10292 21.5486 6 25.3091 6C27.3587 6 28.6969 6.51739 29.5439 7.40195C29.6963 7.56885 29.7472 7.73574 29.7302 7.88595C29.6794 8.08623 29.5269 8.26982 29.2559 8.45341L28.7477 8.80389C28.4598 8.98748 28.2565 9.05424 28.1041 9.05424C27.8669 9.05424 27.7653 8.92072 27.6636 8.83727C27.003 8.26982 26.2069 7.95271 25.1058 7.95271C22.6496 7.95271 20.871 9.53825 20.4475 11.9583C19.9732 14.5452 21.3792 16.0473 23.7676 16.0473C24.8856 16.0473 25.7664 15.7802 26.6303 15.2462C26.9352 15.0626 27.1216 14.9791 27.291 14.9791C27.4434 14.9791 27.5789 15.0459 27.7314 15.2128L28.1549 15.63C28.3412 15.8303 28.392 15.9805 28.3751 16.114C28.3412 16.2976 28.1718 16.4312 28.0532 16.5313C26.9691 17.4325 25.4615 18 23.5643 18Z"
                            fill="white"
                        />
                    </svg>
                );
            }
            case 'care +': {
                return (
                    <svg width="80" height="24" viewBox="0 0 80 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="80" height="24" rx="12" fill="#5CC4ED" />
                        <path
                            d="M46.2594 17.2114L48.0789 6.78857C48.1809 6.15429 48.385 6 49.0142 6H55.323C55.9352 6 56.0713 6.15429 55.9692 6.78857L55.9012 7.2C55.7822 7.81714 55.5781 7.98857 54.9659 7.98857H50.0515L49.5413 10.92H53.4185C54.0477 10.92 54.1837 11.0743 54.0647 11.7086L53.9967 12.1029C53.8946 12.7371 53.7076 12.8914 53.0784 12.8914H49.2012L48.6571 16.0114H53.7586C54.3708 16.0114 54.5068 16.1829 54.4048 16.8L54.3368 17.2114C54.2177 17.8457 54.0307 18 53.4185 18H46.9226C46.2934 18 46.1403 17.8457 46.2594 17.2114Z"
                            fill="white"
                        />
                        <path
                            d="M35.5016 17.2114L37.2137 6.78857C37.3097 6.15429 37.5017 6 38.0938 6H41.0539C44.1261 6 45.6142 7.14857 45.1661 9.73714C44.8621 11.5029 43.8221 12.5486 42.126 12.9771L44.2381 17.3314C44.4621 17.8457 44.3661 18 43.8061 18H43.118C42.526 18 42.334 17.8629 42.126 17.3143L40.1419 13.2H38.2378L37.5817 17.2114C37.4697 17.8457 37.3097 18 36.7017 18H36.1257C35.5336 18 35.3896 17.8457 35.5016 17.2114ZM40.7819 7.98857H39.0858L38.5578 11.2286H40.2539C41.966 11.2286 42.814 10.7829 43.006 9.61714C43.198 8.46857 42.51 7.98857 40.7819 7.98857Z"
                            fill="white"
                        />
                        <path
                            d="M31.388 17.28L30.989 15.4114H26.1059L25.1325 17.28C24.8772 17.8629 24.6538 18 24.0952 18H23.2335C22.7867 18 22.6271 17.7257 22.8665 17.28L28.4996 6.72C28.8028 6.13714 29.0103 6 29.5847 6H30.3507C30.9252 6 31.1007 6.13714 31.1965 6.72L33.4944 17.28C33.5742 17.7257 33.3667 18 32.904 18H32.2337C31.6593 18 31.4837 17.8629 31.388 17.28ZM29.6167 8.72571L27.1591 13.4229H30.5901L29.6167 8.72571Z"
                            fill="white"
                        />
                        <path
                            d="M17.5643 18C13.3803 18 11.4323 15.6467 12.1438 11.758C12.8044 8.10292 15.5486 6 19.3091 6C21.3587 6 22.6969 6.51739 23.5439 7.40195C23.6963 7.56885 23.7472 7.73574 23.7302 7.88595C23.6794 8.08623 23.5269 8.26982 23.2559 8.45341L22.7477 8.80389C22.4598 8.98748 22.2565 9.05424 22.1041 9.05424C21.8669 9.05424 21.7653 8.92072 21.6636 8.83727C21.003 8.26982 20.2069 7.95271 19.1058 7.95271C16.6496 7.95271 14.871 9.53825 14.4475 11.9583C13.9732 14.5452 15.3792 16.0473 17.7676 16.0473C18.8856 16.0473 19.7664 15.7802 20.6303 15.2462C20.9352 15.0626 21.1216 14.9791 21.291 14.9791C21.4434 14.9791 21.5789 15.0459 21.7314 15.2128L22.1549 15.63C22.3412 15.8303 22.392 15.9805 22.3751 16.114C22.3412 16.2976 22.1718 16.4312 22.0532 16.5313C20.9691 17.4325 19.4615 18 17.5643 18Z"
                            fill="white"
                        />
                        <path
                            d="M64.9565 7.17647L64.5013 9.86189H67.0048C67.8899 9.86189 68.0922 10.0921 67.9657 10.9616C67.814 11.9079 67.5105 12.1381 66.6002 12.1381H64.0967L63.6163 14.8235C63.4645 15.7442 63.1864 16 62.2507 16H61.9979C61.0622 16 60.8852 15.7442 61.0369 14.8235L61.5174 12.1381H59.0139C58.1289 12.1381 57.876 11.9335 58.053 11.0384C58.2047 10.0921 58.5082 9.86189 59.4185 9.86189H61.922L62.3772 7.17647C62.5542 6.23018 62.8071 6 63.7427 6H63.9956C64.9312 6 65.1335 6.23018 64.9565 7.17647Z"
                            fill="white"
                        />
                    </svg>
                );
            }
            case 'on demand visit': {
                return (
                    <svg width="119" height="24" viewBox="0 0 119 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="119" height="24" rx="12" fill="#F2F9FC" />
                        <path
                            d="M23.4331 12.1586C22.8406 15.7302 20.3108 18 16.9484 18C13.3617 18 11.4724 15.5967 12.1288 11.8248C12.7533 8.25313 15.2831 6 18.6616 6C22.2162 6 24.0896 8.38665 23.4331 12.1586ZM14.5306 11.9917C14.1463 14.2949 15.123 15.7803 17.1565 15.7803C19.174 15.7803 20.647 14.2949 21.0313 11.9917C21.4156 9.72184 20.4549 8.21975 18.4374 8.21975C16.404 8.21975 14.9149 9.70515 14.5306 11.9917Z"
                            fill="#686D7B"
                        />
                        <path
                            d="M32.4032 14.4117H32.4353L33.6682 7.06815C33.7963 6.3338 34.0044 6.15021 34.7089 6.15021H35.2533C35.9418 6.15021 36.0859 6.3338 35.9578 7.06815L34.2926 16.9152C34.1645 17.6495 33.9724 17.8331 33.2839 17.8331H32.4353C31.7307 17.8331 31.4906 17.6829 31.2344 17.032L28.6725 11.0737C28.3523 10.3227 28.0801 9.77191 28.0801 9.23783H28.0321L26.7351 16.9152C26.607 17.6495 26.4149 17.8331 25.7104 17.8331H25.182C24.4775 17.8331 24.3173 17.6495 24.4454 16.9152L26.1107 7.06815C26.2387 6.3338 26.4469 6.15021 27.1514 6.15021H28.0321C28.7366 6.15021 28.9767 6.30042 29.2329 6.93463L31.8909 12.7594C32.1631 13.3435 32.3872 13.8609 32.4032 14.4117Z"
                            fill="#686D7B"
                        />
                        <path
                            d="M40.0392 17.0571L41.6624 6.94286C41.7873 6.18857 41.9902 6 42.6769 6H45.1586C48.6079 6 50.4964 8.36571 49.8565 12.2229C49.2478 15.9257 46.7818 18 43.551 18H40.7572C40.0704 18 39.9144 17.8114 40.0392 17.0571ZM44.7684 8.26286H43.6914L42.4896 15.7371H43.5666C45.8609 15.7371 47.1407 14.3143 47.5153 12.0343C47.9055 9.6 46.7037 8.26286 44.7684 8.26286Z"
                            fill="#222A3F"
                        />
                        <path
                            d="M50.861 17.0571L52.4842 6.94286C52.609 6.18857 52.812 6 53.4987 6H58.5712C59.2579 6 59.3984 6.18857 59.2735 6.94286L59.2111 7.32C59.1019 8.07429 58.899 8.26286 58.2122 8.26286H54.4976L54.0918 10.7829H57.0729C57.7596 10.7829 57.9001 10.9714 57.7752 11.7257L57.7128 12.1029C57.5879 12.8571 57.385 13.0457 56.6983 13.0457H53.7172L53.2958 15.7371H57.1665C57.8532 15.7371 57.9937 15.9257 57.8689 16.68L57.8064 17.0571C57.6816 17.8114 57.4943 18 56.8075 18H51.5789C50.8922 18 50.7361 17.8114 50.861 17.0571Z"
                            fill="#222A3F"
                        />
                        <path
                            d="M70.0191 17.0571L71.455 8.16C71.1272 9 70.7995 9.84 70.4093 10.6457L67.2565 17.1086C66.8976 17.8286 66.6634 18 65.9767 18H65.5241C64.8529 18 64.6344 17.8286 64.5408 17.1086L63.5419 10.6629C63.4014 9.82286 63.339 8.98286 63.3234 8.12571L61.8875 17.0571C61.7626 17.8114 61.5753 18 60.8886 18H60.3735C59.6868 18 59.5307 17.8114 59.6556 17.0571L61.2788 6.94286C61.4036 6.18857 61.6065 6 62.2933 6H64.1818C64.8686 6 65.0715 6.17143 65.1495 6.89143L66.2108 15.0686L70.0659 6.89143C70.3781 6.17143 70.6434 6 71.3302 6H73.1563C73.8586 6 73.9835 6.18857 73.8586 6.94286L72.2354 17.0571C72.1105 17.8114 71.9389 18 71.2365 18H70.7371C70.0503 18 69.8942 17.8114 70.0191 17.0571Z"
                            fill="#222A3F"
                        />
                        <path
                            d="M81.2404 17.1429L81.0063 15.6343H76.9483L76.246 17.1429C75.965 17.8286 75.6997 18 75.0598 18H74.2014C73.7019 18 73.5302 17.6914 73.78 17.1429L78.7432 6.87429C79.0866 6.17143 79.3207 6 79.9918 6H80.741C81.3965 6 81.5994 6.17143 81.7087 6.85714L83.5036 17.1429C83.5972 17.6914 83.3475 18 82.8168 18H82.2081C81.537 18 81.3497 17.8286 81.2404 17.1429ZM79.9762 9.08571L77.9784 13.3886H80.6474L79.9762 9.08571Z"
                            fill="#222A3F"
                        />
                        <path
                            d="M92.8209 14.4857H92.8521L94.0539 6.94286C94.1788 6.18857 94.3817 6 95.0684 6H95.5991C96.2702 6 96.4107 6.18857 96.2858 6.94286L94.6626 17.0571C94.5377 17.8114 94.3504 18 93.6793 18H92.8521C92.1654 18 91.9313 17.8457 91.6815 17.1771L89.1843 11.0571C88.8721 10.2857 88.6068 9.72 88.6068 9.17143H88.56L87.2958 17.0571C87.1709 17.8114 86.9836 18 86.2969 18H85.7818C85.0951 18 84.939 17.8114 85.0639 17.0571L86.6871 6.94286C86.8119 6.18857 87.0148 6 87.7016 6H88.56C89.2467 6 89.4808 6.15429 89.7306 6.80571L92.3214 12.7886C92.5868 13.3886 92.8053 13.92 92.8209 14.4857Z"
                            fill="#222A3F"
                        />
                        <path
                            d="M97.0592 17.0571L98.6824 6.94286C98.8073 6.18857 99.0102 6 99.6969 6H102.179C105.628 6 107.516 8.36571 106.877 12.2229C106.268 15.9257 103.802 18 100.571 18H97.7772C97.0905 18 96.9344 17.8114 97.0592 17.0571ZM101.788 8.26286H100.711L99.5097 15.7371H100.587C102.881 15.7371 104.161 14.3143 104.535 12.0343C104.926 9.6 103.724 8.26286 101.788 8.26286Z"
                            fill="#222A3F"
                        />
                    </svg>
                );
            }
            default: {
                return <g></g>;
            }
        }
    };

    const iconPath = getVariantPath(variant);

    return iconPath;
};

export default BadgeMembership;
