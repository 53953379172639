import classNames from 'classnames';

import './clock-circle-icon.style.scss';

interface ClockCircleIconProps {
    className?: string;
}

const ClockCircleIcon = ({ className }: ClockCircleIconProps) => {
    const containerClasses = classNames('circle-clock-icon', { [`${className}`]: className });

    return (
        <div className={containerClasses}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path
                        d="M12.5 7.76923V12L16.7985 17.0092M12.5 23C18.5752 23 23.5 18.0752 23.5 12C23.5 5.92487 18.5752 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12C1.5 18.0752 6.42487 23 12.5 23Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </div>
    );
};

export default ClockCircleIcon;
