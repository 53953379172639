import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import ToastBox from 'ui-kit/toast-box/toast-box';

import { MembershipPlanStatusEnum } from 'state/membership/membership.helpers';

import { AlertMessagesProps } from 'types/membership';

import './alert-messages.styles.scss';

const AlertMessages = ({
    planStatus,
    isInvitee,
    isTelemedicine,
    nextPaymentText
}: AlertMessagesProps): ReactElement => {
    const { t } = useTranslation();

    function getMessage(
        status: AlertMessagesProps['planStatus'],
        isInvitee: boolean,
        isTelemedicine?: boolean
    ): string | undefined {
        const memberPaymentDueMessage = t('components.membershipAlertMessages.memberPaymentDue');
        const memberOnHoldMessage = t('components.membershipAlertMessages.memberOnHold');
        const inviteePaymentDueMessage = t('components.membershipAlertMessages.inviteePaymentDue');
        const inviteeOnHoldMessage = t('components.membershipAlertMessages.inviteeOnHold');
        const inviteeCancelledMessage = t('components.membershipAlertMessages.inviteeCancelled');
        const memberTelemedicineMessage = t('components.membershipAlertMessages.memberTelemedicine');

        if (isTelemedicine) return memberTelemedicineMessage;

        switch (status) {
            case MembershipPlanStatusEnum.PaymentDue:
                return isInvitee ? inviteePaymentDueMessage : memberPaymentDueMessage;

            case MembershipPlanStatusEnum.OnHold:
                return isInvitee ? inviteeOnHoldMessage : memberOnHoldMessage;

            case MembershipPlanStatusEnum.Canceled:
                return isInvitee ? inviteeCancelledMessage : undefined;

            default:
                return undefined;
        }
    }

    const iconType = isTelemedicine ? 'info' : 'warning';
    const variantType = isTelemedicine ? 'info' : 'warning';

    return (
        <div className="alert-messages__container">
            {getMessage(planStatus, isInvitee, isTelemedicine) && (
                <ToastBox icon={iconType} variant={variantType}>
                    <p>
                        {!isInvitee && nextPaymentText && <span>{nextPaymentText}</span>}
                        <span>{getMessage(planStatus, isInvitee, isTelemedicine)}</span>
                    </p>
                </ToastBox>
            )}
        </div>
    );
};

export default AlertMessages;
