import classNames from 'classnames';
import { ReactElement } from 'react';

import UIStatuses from 'types/ui-statuses';

import './badge-status.style.scss';

export interface BadgeStatusProps {
    label: string;
    variant?: UIStatuses;
    className?: string;
}

const BadgeStatus = ({ label, variant, className }: BadgeStatusProps): ReactElement => {
    const containerClasses = classNames(
        'badge-status',
        { [`${className}`]: className },
        { [`badge-status--${variant}`]: variant }
    );

    return <span className={containerClasses}>{label}</span>;
};

export default BadgeStatus;
