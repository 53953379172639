import * as Yup from 'yup';

export const MEMBERSHIP_ADD_MEMBER_SCHEMA = Yup.object().shape(
    {
        memberTypeOption: Yup.string()
            .when('selectedMember', {
                is: (selectedMember: any) => !selectedMember,
                then: Yup.string().oneOf(['Adult', 'Minor']).required('Please select a member type').nullable()
            })
            .nullable(),
        selectedMember: Yup.object()
            .when('memberTypeOption', {
                is: (memberTypeOption: any) => !memberTypeOption,
                then: Yup.object()
                    .shape({
                        ePostPatientNum: Yup.string().required('Please select a member').nullable(),
                        memberName: Yup.string().required('Please select a member').nullable()
                    })
                    .nullable()
            })
            .nullable()
    },
    [['selectedMember', 'memberTypeOption']]
);
