import { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// Interfaces and types
import { ManageGroupCardListProps } from 'types/membership';

// Components
import ManageGroupCard from '../manage-group-card';
import ToastBox from 'ui-kit/toast-box/toast-box';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';

//Styles
import './manage-group-card-list.style.scss';

const ManageGroupCardList = ({
    payload,
    className,
    isSingleAccount,
    hideContactDetails = false
}: ManageGroupCardListProps): ReactElement => {
    const containerClasses = classNames('manage-group-card-list', { [`${className}`]: className });
    const { t } = useTranslation();
    return (
        <div className={containerClasses}>
            <div className="manage-group-card-list__container-list">
                {payload &&
                    payload.map((groups, index) => (
                        <div key={`manage-group-card-item-${index}`}>
                            <ManageGroupCard
                                {...groups}
                                isSingleAccount={isSingleAccount}
                                hideContactDetails={hideContactDetails}
                            />
                            {(groups.accountType === 'Minor' || groups.accountType === 'Child') &&
                                groups.isMinorDisclaimerVisible && (
                                    <ToastBox variant="info">
                                        <div className="request-visit-toast-box">
                                            <div>
                                                <CircleInfo />
                                            </div>
                                            <p>
                                                {t('pages.requestTelemedicineVisit.patientInformation.minorToastBox')}
                                            </p>
                                        </div>
                                    </ToastBox>
                                )}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ManageGroupCardList;
