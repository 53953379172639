import { ReactElement } from 'react';
import classNames from 'classnames';

// Interfaces and types
import { PlanInviteCardListProps } from 'types/membership';

// Components
import PlanInviteCard from '../plan-invite-card';

//Styles
import './plan-invite-card-list.styles.scss';

const PlanInviteCardList = ({ payload, className }: PlanInviteCardListProps): ReactElement => {
    const containerClasses = classNames('plan-invite-card-list', { [`${className}`]: className });
    return (
        <div className={containerClasses}>
            <div className="plan-invite-card-list__container-list">
                {payload &&
                    payload.map((invitee, index) => (
                        <div key={`plan-invite-card-item-${index}`}>
                            <PlanInviteCard {...invitee} />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default PlanInviteCardList;
